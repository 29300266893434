import { GetFollowUpsStatusesAll, GetFollowUpsStatusesTree } from "@/views/client/clientFollowUpsStatuses/api";
import { GetUsersAll } from "@/views/users/api";
import { ShowApiError } from "@/request/error";
import { compareReverse } from "@/utils/common";
export default {
    data() {
        return {
            userOptions: [],
            followUpStatusOptions: [],
            followUpStatusTreeOptions: [],
        }
    },
    computed: {
        _clientOptions() {
            return this.$store.state.basicOption.clientOptions
        }
    },
    methods: {
        initFormOptions() {
            GetUsersAll().then((res) => {
                this.userOptions = res.data;
            })
            GetFollowUpsStatusesAll()
                .then((res) => {
                    this.followUpStatusOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取跟进状态选单失败", err);
                });
            GetFollowUpsStatusesTree()
                .then((res) => {
                    this.followUpStatusTreeOptions = this.formatData(res.data);
                })
                .catch((err) => {
                    ShowApiError("获取跟进状态树形选单失败", err);
                });
        },
        // 格式化数据
        formatData(data = []) {
            let results = [];
            if (data.length > 0) {
                data.forEach((item) => {
                    if (item.subs.length > 0) {
                        results.push({
                            ...item,
                            subs: this.formatData(item.subs),
                        });
                    } else {
                        delete item.subs
                        results.push(item)
                    }
                });
            }
            return results.sort(compareReverse("seq"));
        },
    }
}