<template>
  <section class="follow-ups-record-wrap">
    <DetailBlock name="跟进记录信息">
      <template #right
        ><el-button
          class="float-r"
          size="small"
          @click="formDialog = true"
          v-if="
            checkPermission([
              'CLIENT_ADMIN',
              'CLIENT_GROUP_ADMIN',
              'CLIENT_USER',
            ])
          "
          >添加跟进记录</el-button
        ></template
      >
      <NoData v-if="recordLists.length <= 0" />
      <el-timeline v-if="recordLists.length > 0">
        <el-timeline-item
          v-for="(item, index) in recordLists"
          :color="item.color"
          :key="item.id"
          :timestamp="item.date"
          placement="top"
        >
          <el-card shadow="hover">
            <p class="record-text">
              <span class="start" style="margin-right: 15px">
                <i v-show="item.star"
                  class="el-icon-star-on text-start"
                  style="cursor: pointer;font-size:20px"
                ></i>
                <i v-show="!item.star"
                  class="el-icon-star-off"
                  style="cursor: pointer;font-size:17px"
                ></i>
              </span>
              <el-tag style="margin-right: 15px">{{
                item.followUpStatusName
              }}</el-tag>
            </p>
            <div class="record-content">
              <span class="lable">跟进内容：</span>
              <div class="content">
                {{item.content}}
              </div>
            </div>
            <div class="record-content" v-if="item.files.length > 0">
              <span class="lable">附件：</span>
              <div class="content">
                <template v-for="item in item.files">
                  <FilesItem
                    :item="item"
                    :showFileName="false"
                    :showFileDesc="false"
                    :showCheckBox="false"
                    :key="item.id"
                    class="mt-r"
                  />
                </template>
              </div>
            </div>
            <div class="record-update-info clearfix">
              <span
                class="float-l"
                style="cursor: pointer"
                type="text"
                v-if="
                  checkPermission([
                    'CLIENT_ADMIN',
                    'CLIENT_GROUP_ADMIN',
                    'CLIENT_USER',
                    'CLIENT_OBSERVER',
                  ])
                "
                @click="handleStart(item, index)"
                >{{ item.star ? "取消星标" : "设为星标" }}</span
              >
              {{ item.lastModifiedByName }} 更新于
              {{ item.lastModifiedAt | date_time }}
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <section class="pagenation" v-if="recordLists.length > 0">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="page + 1"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </section>
    </DetailBlock>
    <el-dialog
      title="添加跟进记录"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <Form :clientId="currentClientId" v-if="formDialog" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import { GetClientFollowUpsRecordList } from "@/views/client/clientFollowUpsRecord/api";
import Form from "@/views/client/clientFollowUpsRecord/components/Form";
import comonControl from "@/views/client/clientFollowUpsRecord/mixins/comonControl";
import { randomRgbaColorGroup } from "@/utils/common";
import { format } from "date-fns";
import NoData from "@/components/NoData";
import { checkPermission } from "@/utils/auth";
import { isEmprty } from "@/utils/validate";
import DetailBlock from "@/components/DetailBlock";
import FilesItem from "@/views/filesModule/components/FilesItem";
export default {
  components: { NoData, Form, DetailBlock, FilesItem },
  mixins: [comonControl],
  props: {
    clientId: {
      type: [Number],
      default: null,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      formDialog: false,
      page: 0,
      size: 20,
      total: 0,
      recordLists: [],
      currentClientId: null
    };
  },
  watch: {
    clientId: {
      handler() {
        if (!isEmprty(this.clientId)) {
          this.currentClientId = this.clientId
          this.getClientFollowUpRecord();
        } else {
          this.currentClientId = null
          this.page = 0;
          this.recordLists = [];
          this.loading = false;
        }
      },
      immediate: true,
    },
    refresh: {
      handler() {
        if (this.refresh) {
          this.getClientFollowUpRecord();
        }
      },
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    getClientFollowUpRecord() {
      this.loading = true;
      GetClientFollowUpsRecordList({
        page: this.page,
        size: this.size,
        clientId: this.currentClientId,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.recordLists = content.map((item) => {
            return {
              ...item,
              ...this.formatItem(item),
            };
          });
          this.total = totalElements;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatItem(data) {
      return {
        color: randomRgbaColorGroup()[10],
        date: `${data.createdByName} 发布于 ${format(
          new Date(data.createdAt),
          "yyyy-MM-dd HH:mm:ss"
        )}`,
      };
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getClientFollowUpRecord();
    },
    handleStart(item, index) {
      if (item.star) {
        this.onToggleStart(item.id, false).then((res) => {
          this.$set(this.recordLists, index, {
            ...res.data,
            ...this.formatItem(res.data),
          });
        });
      } else {
        this.onToggleStart(item.id, true).then((res) => {
          this.$set(this.recordLists, index, {
            ...res.data,
            ...this.formatItem(res.data),
          });
        });
      }
    },
    onFormCancel() {
      this.formDialog = false;
    },
    onFormSuccess() {
      this.formDialog = false;
      this.getClientFollowUpRecord();
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-ups-record-wrap {
  box-sizing: border-box;
  width: 100%;
  .header {
    margin-bottom: 15px;
    text-align: right;
    min-height: 40px;
  }
  .record-text {
    padding-bottom: 15px;
    font-size: 15px;
    color: #000;
  }
  .record-file {
    padding-bottom: 15px;
  }
  .record-update-info {
    font-size: 13px;
    color: #989898;
    text-align: right;
  }
  .record-content {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding-left: 80px;
    padding-bottom: 15px;
    .lable {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 14px;
      color: #6b6b6b;
    }
    .content {
      padding: 0;
    }
  }
  ::v-deep {
    .el-timeline-item__timestamp {
      font-size: 15px;
      &.is-top {
        margin-bottom: 15px;
      }
    }
  }

  .pagenation {
    margin-top: 25px;
    text-align: right;
  }
}
</style>