<template>
  <section class="client-payment-wrap">
    <DetailBlock name="收款信息">
      <template #right
        ><el-button
          class="float-r"
          size="small"
          @click="formDialog = true"
          v-if="
            checkPermission([
              'PAYMENT_ADMIN',
              'PAYMENT_GROUP_ADMIN',
              'PAYMENT_USER',
            ])
          "
          >添加收款记录</el-button
        ></template
      >
      <NoData v-if="tableData.length <= 0" />
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-if="tableData.length > 0"
      >
        <el-table-column
          prop="disabled"
          label="状态"
          width="100"
          align="center"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
            <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="typeName"
          label="收款类型"
          width="180"
          fixed="left"
        >
        </el-table-column>
        <el-table-column prop="amount" label="金额">
          <template slot-scope="scope">
            {{ scope.row.amount | currency }}
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建日期"
          ><template slot-scope="scope">
            {{ scope.row.createdAt | date_time }}
          </template>
        </el-table-column>
        <el-table-column prop="payAt" label="收款时间">
          <template slot-scope="scope">
            {{ scope.row.payAt | date_time }}
          </template>
        </el-table-column>
        <el-table-column
          prop="control"
          label="操作"
          width="130px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              详情
            </el-button>
            <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              :loading="scope.row.canDisableChecking"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled"
              :loading="scope.row.canDisableChecking"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagenation" v-if="tableData.length > 0">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="page + 1"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </section>
    </DetailBlock>
    <el-dialog
      title="收款详情"
      :visible.sync="detaiDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      @close="currentId = null"
    >
      <Detail :id="currentId" />
    </el-dialog>
    <el-dialog
      :title="isEmprty(currentId) ? '添加收款记录' : '修改收款记录'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <Form
        :id="currentId"
        :clientId="currentClientId"
        @cancel="onFormCancel"
        @success="onFormSuccess"
      />
    </el-dialog>
  </section>
</template>

<script>
import { GetPaymentsList } from "@/views/payments/api";
import Form from "@/views/payments/components/Form";
import Detail from "@/views/payments/components/Detail";
import NoData from "@/components/NoData";
import { checkPermission } from "@/utils/auth";
import { isEmprty } from "@/utils/validate";
import paymentControl from "@/views/payments/mixins/comonControl.js";
import DetailBlock from "@/components/DetailBlock";
export default {
  components: { NoData, Form, DetailBlock, Detail },
  mixins: [paymentControl],
  props: {
    clientId: {
      type: [Number],
      default: null,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentId: null,
      currentClientId: null,
      formDialog: false,
      detaiDialog: false,
      loading: false,
      page: 0,
      size: 20,
      total: 0,
      tableData: [],
    };
  },
  watch: {
    clientId: {
      handler() {
        if (!isEmprty(this.clientId)) {
          this.currentClientId = this.clientId;
          this.getClientPayments();
        } else {
          this.currentClientId = null;
          this.page = 0;
          this.tableData = [];
          this.loading = false;
        }
      },
      immediate: true,
    },
    refresh: {
      handler() {
        if (this.refresh) {
          this.getClientPayments();
        }
      },
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    getControlColumn() {
      return {
        canDisableChecking: false,
        canUpdateChecking: false,
        canUpdate: false,
        canDisable: false,
      };
    },
    getClientPayments() {
      this.loading = true;
      GetPaymentsList({
        page: this.page,
        size: this.size,
        clientId: this.currentClientId,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              ...this.getControlColumn(),
            };
          });
          this.total = totalElements;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.page = 0;
      this.getClientPayments();
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getClientPayments();
    },
    editRow(row) {
      this.currentId = row.id;
      this.formDialog = true;
    },
    showRow(row) {
      this.currentId = row.id;
      this.detaiDialog = true;
    },
    disableRow(row, index) {
      this.ToggleDisable(row, true).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
    enableRow(row, index) {
      this.ToggleDisable(row, false).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
    onFormCancel() {
      this.currentId = null;
      this.formDialog = false;
    },
    onFormSuccess() {
      this.currentId = null;
      this.formDialog = false;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 15px;
  text-align: right;
  min-height: 40px;
}
.pagenation {
  margin-top: 25px;
  text-align: right;
}
</style>