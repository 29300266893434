<template>
  <div class="base-detail">
    <NoData v-if="!detail.id" />
    <el-form
      v-if="detail.id"
      label-position="left"
      label-width="auto"
      :model="detail"
    >
      <DetailBlock name="基本信息">
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="客户品牌：">
              <span>{{ detail.brand }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="跟进状态：">
              <span>{{ detail.followUpStatusName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="负责人：">
              <span>{{ detail.ownerName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="客户来源：">
              <span>{{ detail.sourceName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="客户阶段：">
              <span>{{ detail.stageName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="客户类型：">
              <span>{{ detail.typeName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="签约人：">
              <span>{{ detail.signedUserName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="签约时间：">
              <span>{{ detail.signedAt | date }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="是否星标：">
              <span v-if="detail.star"
                ><i class="el-icon-star-on text-start"></i>&nbsp;星标客户</span
              >
              <span v-if="!detail.star"
                ><i class="el-icon-star-off"></i>&nbsp;非星标客户</span
              >
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="启/禁用状态：">
              <el-tag type="success" :size="tagSize" v-if="!detail.disabled"
                >启用</el-tag
              >
              <el-tag type="danger" :size="tagSize" v-if="detail.disabled"
                >禁用</el-tag
              >
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="自动拉取：">
              <el-tag :type="detail.isFetched ? 'success' : 'danger'">{{
                detail.isFetched ? "是" : "否"
              }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :md="24">
            <el-form-item label="客户标签：">
              <el-tag
                class="tag"
                v-for="item in detail.tags"
                :key="item.id"
                :size="tagSize"
                type="info"
              >
                <i class="el-icon-price-tag"></i>
                {{ item.name }}
              </el-tag>
              <el-button size="mini" @click="handleSetTag">点击设置</el-button>
            </el-form-item>
          </el-col>
          <el-col :md="24">
            <el-form-item label="备注:">
              <span>{{ detail.remark }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </DetailBlock>
      <DetailBlock name="地址信息">
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="国家：">
              <span>{{ detail.address && detail.address !==null ? detail.address.country : "/" }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="省份/州：">
              <span>{{ detail.address && detail.address !==null ? detail.address.province : "/" }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="城市：">
              <span>{{ detail.address && detail.address !==null ? detail.address.city : "/" }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="地区：">
              <span>{{ detail.address && detail.address !==null ? detail.address.region : "/" }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <el-form-item label="街道：">
              <span>{{ detail.address && detail.address !==null ? detail.address.street : "/" }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </DetailBlock>
      <DetailBlock name="联系人信息">
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人：">
              <span>{{
                detail.mainContact && detail.mainContact !== null
                  ? detail.mainContact.name
                  : "/"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人电话：">
              <span>{{
                detail.mainContact && detail.mainContact !== null
                  ? detail.mainContact.phoneNo
                  : "/"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人职位：">
              <span>{{
                detail.mainContact && detail.mainContact !== null
                  ? detail.mainContact.position
                  : "/"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人邮箱：">
              <span>{{
                detail.mainContact && detail.mainContact !== null
                  ? detail.mainContact.email
                  : "/"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人QQ：">
              <span>{{
                detail.mainContact && detail.mainContact !== null
                  ? detail.mainContact.qq
                  : "/"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人微信：">
              <span>{{
                detail.mainContact && detail.mainContact !== null
                  ? detail.mainContact.wx
                  : "/"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="主要联系人地址：">
              <span
                v-if="
                  detail.mainContact !== null &&
                  detail.mainContact.address !== null
                "
                >{{
                  `${detail.mainContact.address.country}/${detail.mainContact.address.province}/${detail.mainContact.address.city}/${detail.mainContact.address.region}`
                }}</span
              >
              <span v-else>{{ "/" }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="center">更多联系人</el-divider>
        <el-table :data="detail.contacts" stripe style="width: 100%">
          <el-table-column prop="name" label="姓名" align="left">
          </el-table-column>
          <el-table-column prop="phoneNo" label="电话" align="left">
          </el-table-column>
          <el-table-column prop="position" label="职位" align="left">
          </el-table-column>
          <el-table-column prop="email" label="邮箱" align="left">
          </el-table-column>
          <el-table-column prop="qq" label="QQ" align="left"> </el-table-column>
          <el-table-column prop="wx" label="微信" align="left">
          </el-table-column>
          <el-table-column prop="address" label="地址" align="left">
            <template slot-scope="scope">
              {{
                scope.row.address !== null
                  ? `${scope.row.address.country}/${scope.row.address.province}/${scope.row.address.city}/${scope.row.address.region}`
                  : "/"
              }}
            </template>
          </el-table-column>
        </el-table>
      </DetailBlock>
      <DetailBlock name="其他信息">
        <el-row :gutter="25">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="创建人：">
              <span>{{ detail.createdByName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="创建日期：">
              <span>{{ detail.createdAt | date_time }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="最后修改日期：">
              <span>{{ detail.lastModifiedAt | date_time }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="最后修改用户：">
              <span>{{ detail.lastModifiedByName }}</span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="最后修改阶段日期：">
              <span>{{ detail.lastStageAt | date_time }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </DetailBlock>
    </el-form>
  </div>
</template>

<script>
import DetailBlock from "@/components/DetailBlock";
import NoData from "@/components/NoData";
export default {
  components: { DetailBlock, NoData },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tagSize: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSetTag() {
      this.$emit("onTagSet");
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  margin-right: 10px;
}
</style>