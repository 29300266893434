import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的合同列表
export function GetContractsAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/contracts/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取合同的分页列表
export function GetContractsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/contracts/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的合同数据
export function GetContractsById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/contracts/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定合同的数据
export function PutContractsById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/contracts/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建合同
export function PostContracts(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/contracts`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// // 删除指定合同的数据
// export function DeleteContractsById(id = "") {
//     return new Promise((resolve, reject) => {
//         apiv1.delete(`/contracts/${id}`)
//             .then(res => {
//                 if (res.status === 204) {
//                     resolve(res)
//                 }
//             })
//             .catch(err => {
//                 reject(err)
//             });
//     })
// }

// // 批量删除
// export function MultipleDeleteContracts(multipleSelectieIds = []) {
//     return new Promise((resolve, reject) => {
//         let multipleRequests = []
//         multipleSelectieIds.forEach(id => {
//             multipleRequests.push(apiv1.delete(`/contracts/${id}`))
//         })
//         axios.all(multipleRequests)
//             .then(axios.spread(() => {
//                 resolve();
//             })).catch(err => {
//                 reject(err)
//             });
//     })
// }

// 禁用\启用指定用户
export function ToggleDisableContractsById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/contracts/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态批量设置启用\禁用
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
export function ToggleMultipleDisableContracts(multipleSelectieIds = [], bool = false) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.put(`/contracts/${id}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

// 获取文件，比如图片
export function GetContractsFiles(id = "", fileName = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/contracts/${id}/files/${fileName}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
 export function GetDiasbleCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/contracts/${id}/can/disable`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
 export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/contracts/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}