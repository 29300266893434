<template>
  <div class="contracts-wrap">
    <DetailBlock name="合同信息">
      <template #right
        ><el-button
          size="small"
          @click="addContracts"
          v-if="
            checkPermission([
              'PAYMENT_ADMIN',
              'PAYMENT_GROUP_ADMIN',
              'PAYMENT_USER',
            ])
          "
          >添加合同信息</el-button
        ></template
      >
      <NoData v-if="tableData.length <= 0" />
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-if="tableData.length > 0"
      >
        <el-table-column
          prop="disabled"
          label="状态"
          width="100"
          align="center"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
            <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="contractNo"
          label="合同号"
          min-width="150"
          align="center"
          fixed="left"
        >
          <template slot-scope="scope">
            <span class="text-nav" @click="showDetail(scope.row)">{{
              scope.row.contractNo
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="金额" :width="130">
          <template slot-scope="scope">
            {{ scope.row.amount | currency }}
          </template>
        </el-table-column>
        <el-table-column
          prop="clientSignDate"
          label="客户签字日期"
          :width="200"
        ></el-table-column>
        <el-table-column
          prop="clientSignatoryName"
          label="客户签字人姓名"
          :min-width="130"
        ></el-table-column>
        <el-table-column
          prop="clientSignatoryPosition"
          label="客户签字人职位"
          :width="130"
        ></el-table-column>
        <el-table-column
          prop="companySignDate"
          label="公司签字日期"
          :min-width="130"
        ></el-table-column>
        <el-table-column
          prop="companySignatoryName"
          label="公司签字人姓名"
          :min-width="130"
        ></el-table-column>
        <el-table-column
          prop="startDate"
          label="合同开始日期"
          :width="150"
        ></el-table-column>
        <el-table-column
          prop="endDate"
          label="合同结束日期"
          :width="150"
        ></el-table-column>
        <el-table-column
          prop="expireDate"
          label="合同到期日期"
          :width="150"
        ></el-table-column>
        <el-table-column
          prop="expireRemindDate"
          label="合同到期提醒日期"
          :width="200"
        ></el-table-column>
        <el-table-column
          prop="ownerName"
          label="所有人"
          :width="150"
        ></el-table-column>
        <el-table-column
          prop="signAddress"
          label="合同签订地点"
          :min-width="130"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          :min-width="130"
        ></el-table-column>
        <el-table-column
          prop="term"
          label="特殊条款"
          :min-width="130"
        ></el-table-column>
        <el-table-column prop="createdAt" label="创建日期" :width="200"
          ><template slot-scope="scope">
            {{ scope.row.createdAt | date_time }}
          </template>
        </el-table-column>
        <el-table-column
          prop="control"
          label="操作"
          width="130px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                checkPermission([
                  'CONTRACT_ADMIN',
                  'CONTRACT_GROUP_ADMIN',
                  'CONTRACT_USER',
                  'CONTRACT_OBSERVER',
                ])
              "
              @click.native.prevent="showDetail(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              详情
            </el-button>
            <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              :loading="scope.row.canDisableChecking"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled"
              :loading="scope.row.canDisableChecking"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagenation" v-if="tableData.length > 0">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="page + 1"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </section>
    </DetailBlock>
  </div>
</template>

<script>
import { GetContractsList } from "@/views/contracts/api";
import NoData from "@/components/NoData";
import { checkPermission } from "@/utils/auth";
import { isEmprty } from "@/utils/validate";
import DetailBlock from "@/components/DetailBlock";
import commonControl from "@/views/contracts/mixins/commonControl";
export default {
  components: { NoData, DetailBlock },
  mixins: [commonControl],
  props: {
    clientId: {
      type: [Number],
      default: null,
    },
    clientName:{
      type: [String],
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      page: 0,
      size: 20,
      total: 0,
      tableData: [],
      currentClientId: null,
    };
  },
  watch: {
    clientId: {
      handler() {
        if (!isEmprty(this.clientId)) {
          this.currentClientId = Number(this.clientId);
          this.getClientContracts();
        } else {
          this.currentClientId = null;
          this.page = 0;
          this.tableData = [];
          this.loading = false;
        }
      },
      immediate: true,
    },
    refresh: {
      handler() {
        if (this.refresh) {
          this.getClientContracts();
        }
      },
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    getControlColumn() {
      return {
        canDisableChecking: false,
        canUpdateChecking: false,
        canUpdate: false,
        canDisable: false,
      };
    },
    getClientContracts() {
      this.loading = true;
      GetContractsList({
        page: this.page,
        size: this.size,
        clientId: this.currentClientId,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              ...this.getControlColumn(),
            };
          });
          this.total = totalElements;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.page = 0;
      this.getClientContracts();
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getClientContracts();
    },
    addContracts() {
      console.log("传入到合同的客户id", this.currentClientId);
      this.$router.push({
        name: "ContractsForm",
        params: {
          id: null,
          clientId: this.currentClientId,
        },
        query:{
          name: this.clientName
        }
      });
    },
    editRow(row) {
      this.currentId = row.id;
      this.$router.push({
        name: "ContractsForm",
        params: {
          id: row.id,
        },
      });
    },
    showDetail(row) {
      this.$router.push({
        name: "ContractsDetail",
        params: {
          id: row.id,
        },
      });
    },
    disableRow(row, index) {
      this.ToggleDisableContract(row, true).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
    enableRow(row, index) {
      this.ToggleDisableContract(row, false).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 15px;
  text-align: right;
  min-height: 40px;
}
.pagenation {
  margin-top: 25px;
  text-align: right;
}
</style>