import {
    ToggleStartClientFollowUpsRecordById
} from "../api";
import { ShowApiError } from "@/request/error";
export default {
    methods: {
        onToggleStart(id = "", flag = null) {
            return new Promise((resolve, reject) => {
                ToggleStartClientFollowUpsRecordById(id, flag)
                    .then((res) => {
                        this.$message.success("设置成功!");
                        resolve(res);
                    })
                    .catch((err) => {
                        ShowApiError("设置请求错误", err);
                        reject(err);
                    });
            })

        },
    }
}