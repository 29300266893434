import { render, staticRenderFns } from "./ClientContracts.vue?vue&type=template&id=1521ae90&scoped=true&"
import script from "./ClientContracts.vue?vue&type=script&lang=js&"
export * from "./ClientContracts.vue?vue&type=script&lang=js&"
import style0 from "./ClientContracts.vue?vue&type=style&index=0&id=1521ae90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1521ae90",
  null
  
)

export default component.exports