<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="100px"
    class="demo-ruleForm"
    :disabled="submitting"
  >
    <el-form-item label="关联客户：" prop="clientId">
      <BasicSelect
        v-model="form.clientId"
        :disabled="!isEmprty(clientId)"
        :allOptions="_clientOptions"
      />
    </el-form-item>
    <el-form-item label="跟进状态：" prop="followUpStatusId">
      <el-cascader
        style="width: 100%"
        v-model="form.followUpStatusId"
        :props="{
          label: 'name',
          value: 'id',
          children: 'subs',
          emitPath: false,
        }"
        :options="followUpStatusTreeOptions"
        :show-all-levels="false"
        clearable
      ></el-cascader>
    </el-form-item>
    <el-form-item label="跟进内容：" prop="content">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="form.content"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="下次跟进时间" prop="nextFollowUpDate">
      <el-date-picker
        style="width: 100%"
        v-model="form.nextFollowUpDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="点击选择"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="是否星标：" prop="star">
      <el-radio-group v-model="form.star">
        <el-radio :label="true">星标</el-radio>
        <el-radio :label="false">非星标</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="上传文件：" prop="fileIds">
      <el-button type="primary" size="small" @click="showFileDialog = true">{{
        fileList.length > 0 ? `已选${fileList.length}个文件` : "选择文件"
      }}</el-button>
      <div class="file-content-list" v-if="fileList.length > 0">
        <template v-for="(item, index) in fileList">
          <FilesItem
            showDelete
            :showFileName="false"
            :showFileDesc="false"
            :showCheckBox="false"
            :item="item"
            @onDelete="onFileDelete(index)"
            :key="item.id"
            class="mt-r"
          />
        </template>
      </div>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button @click="onCancel('form')">关 闭</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </el-form-item>
    <el-dialog
      title="选择文件"
      :visible.sync="showFileDialog"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <FilesView
        ref="FilesView"
        :fileItems="fileList"
        @confirm="onFileConfirm"
        @cancel="onFileCancel"
      />
    </el-dialog>
  </el-form>
</template>

<script>
import { PostClientFollowUpsRecord } from "../api";
import { ShowApiError } from "@/request/error";
import formSelection from "../mixins/formSelection";
import { isEmprty } from "@/utils/validate";
import FilesView from "@/views/filesModule/FilesView";
import FilesItem from "@/views/filesModule/components/FilesItem";
import BasicSelect from "@/components/BasicSelect";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    clientId: {
      type: [Number],
      default: null,
    },
  },
  components: {
    FilesView,
    FilesItem,
    BasicSelect,
  },
  mixins: [formSelection],
  data() {
    return {
      submitting: false,
      showFileDialog: false,
      form: {
        clientId: null,
        content: "",
        followUpStatusId: null,
        nextFollowUpDate: "",
        star: false,
        fileIds: [],
      },
      fileList: [],
      rules: {
        clientId: [
          { required: true, message: "请选择客户", trigger: ["change"] },
        ],
        followUpStatusId: [
          { required: true, message: "请选择跟进状态", trigger: ["change"] },
        ],
        content: [
          { required: true, message: "请输入跟进内容", trigger: ["blur"] },
        ],
      },
    };
  },
  created() {
    this.initFormOptions();
  },
  watch: {
    clientId: {
      handler() {
        this.initClientParams();
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    initClientParams() {
      if (!isEmprty(this.clientId)) {
        this.form.clientId = this.clientId;
      } else {
        this.form.clientId = null;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostClientFollowUpsRecord({
            ...this.form,
            fileIds: this.fileList.map((item) => item.id),
          })
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新建成功!");
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("提交新建错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        clientId: null,
        content: "",
        followUpStatusId: null,
        nextFollowUpDate: "",
        star: false,
        fileIds: [],
      };
      this.fileList = [];
      this.initClientParams();
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].clearValidate();
        }
      });
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
    onFileConfirm({ items }) {
      this.fileList = items;
      this.showFileDialog = false;
    },
    onFileCancel() {
      this.showFileDialog = false;
    },
    onFileDelete(index) {
      this.fileList.splice(index, 1);
    },
  },
};
</script>

<style>
</style>