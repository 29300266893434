<template>
  <section class="base-page" :class="showQuickList ? 'show-quick-list' : ''">
    <section class="base-page-container" v-loading="loading">
      <section class="page-header base-shadow">
        <h3 class="header-title">
          <span class="header-title-name">
            <i class="text-start el-icon-star-on" v-if="detail.star"></i>
            <span>&nbsp;{{ detail.name }}&nbsp;</span>
            <el-tag type="success" size="mini" v-if="!detail.disabled"
              >启用</el-tag
            >
            <el-tag type="danger" size="mini" v-if="detail.disabled"
              >禁用</el-tag
            >
            <CheckClaim class="mt-l" size="mini" :clientId="detail.id" />
          </span>

          <div class="page-control">
            <el-button
              size="small"
              plain
              @click="editRow(detail)"
              :loading="detail.canUpdateChecking"
              :disabled="submitting"
              >{{
                detail.canUpdateChecking ? "权限检验中..." : "修改"
              }}</el-button
            >
            <el-button
              size="small"
              plain
              @click="onSetSign"
              v-if="detail.canSign"
              :loading="canUpdateSignChecking"
              >{{ detail.canUpdateSignChecking ? "权限检验中..." : "设置签约" }}
            </el-button>
            <el-button size="small" plain @click="onShowTagDialog"
              >设置标签</el-button
            >
            <el-button
              size="small"
              plain
              @click="handleStartClient"
              icon="el-icon-star-on"
              v-if="!detail.star"
              :disabled="submitting"
              >设为星标</el-button
            >
            <el-button
              size="small"
              plain
              @click="handleUnStartClient"
              icon="el-icon-star-off"
              v-if="detail.star"
              :disabled="submitting"
              >取消星标</el-button
            >
            <el-button
              size="small"
              type="success"
              @click="handleEnableClient"
              plain
              v-if="detail.disabled"
              :disabled="submitting"
              :loading="detail.canDisableChecking"
              >{{
                detail.canDisableChecking ? "权限检验中..." : "启用"
              }}</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="handleDisableClient"
              plain
              v-if="!detail.disabled"
              :disabled="submitting"
              :loading="detail.canDisableChecking"
              >{{
                detail.canDisableChecking ? "权限检验中..." : "禁用"
              }}</el-button
            >
            <el-button
              title="快速切换列表"
              size="small"
              :type="showQuickList ? 'primary' : ''"
              :icon="
                showQuickList ? 'el-icon-arrow-right' : 'el-icon-arrow-left'
              "
              @click="showQuickList = !showQuickList"
            ></el-button>
          </div>
        </h3>
        <div class="tags-group" v-if="detail.tags && detail.tags.length > 0">
          <el-tag
            class="tag"
            v-for="item in detail.tags"
            :key="item.id"
            :size="tagSize"
            type="info"
          >
            <i class="el-icon-price-tag"></i>
            {{ item.name }}
          </el-tag>
        </div>
        <section class="clients-desc" v-if="detail.id">
          <el-form label-position="left" label-width="auto" :model="detail">
            <el-row :gutter="25">
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="主要联系人：">
                  <span>{{
                    detail.mainContact && detail.mainContact !== null ? detail.mainContact.name : "/"
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="主要联系人电话：">
                  <span>{{
                    detail.mainContact && detail.mainContact !== null ? detail.mainContact.phoneNo : "/"
                  }}</span>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="负责人：">
                  <span>{{ detail.ownerName }}</span>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="跟进状态：">
                  <span>{{ detail.followUpStatusName }}</span>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="客户阶段：">
                  <span>{{ detail.stageName }}</span>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8">
                <el-form-item label="客户来源：">
                  <span>{{ detail.sourceName }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </section>
        <section class="client-control-row">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="client-tab-wrap">
                <span
                  class="client-tab-item"
                  :class="[tabActive == item.id ? 'active' : '']"
                  v-for="item in tabs"
                  :key="`tab${item.id}`"
                  @click="onTabChange(item)"
                >
                  <svg-icon v-if="item.icon" :iconClass="item.icon"></svg-icon>
                  {{ item.name }}</span
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div class="client-sttting-wrap">
                <el-row>
                  <el-col :span="12">
                    <el-select
                      class="base-select"
                      v-model="detail.stageId"
                      placeholder="请选择客户阶段"
                      filterable
                      :loading="submitting"
                      :disabled="detail.canUpdateStageChecking"
                      @change="onStageChange"
                    >
                      <el-option
                        v-for="item in stageOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <el-select
                      class="base-select"
                      v-model="detail.ownerId"
                      placeholder="请选择负责人"
                      filterable
                      :loading="submitting"
                      :disabled="detail.canUpdateOwnerChecking"
                      @change="onOwnerChange"
                    >
                      <el-option
                        v-for="item in ownerOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </section>
      </section>
      <section class="base-page-section base-shadow">
        <BaseDetail
          :detail="detail"
          :tagSize="tagSize"
          v-loading="loading"
          v-show="tabActive == 0"
          @onTagSet="onShowTagDialog"
        />
        <ClientFollowUpRecord
          :clientId="currentId"
          :refresh="tabActive == 1"
          v-show="tabActive == 1"
        />
        <ClientContracts
          :clientId="currentId"
          :clientName="$route.query.name"
          :refresh="tabActive == 2"
          v-show="tabActive == 2"
        />
        <ClientPayments
          :clientId="currentId"
          :refresh="tabActive == 3"
          v-show="tabActive == 3"
        />
        <ClientInvitations
          :clientId="currentId"
          :refresh="tabActive == 4"
          v-show="tabActive == 4"
        />
      </section>
    </section>
    <section class="client-quick-list-wraper" :class="showQuickList ? 'open' : 'close'">
      <QuickClientTableList />
    </section>
    <el-dialog
      title="设置签约客户"
      :visible.sync="showSingDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
    >
      <ClientSignForm
        :clientId="currentId"
        :signedAt="detail.signedAt"
        :signedUserId="detail.signedUserId"
        @cancel="onFormCancel"
        @success="onFormSuccess"
      />
    </el-dialog>
    <el-dialog
      :title="detail.name ? `设置【${detail.name}】的标签` : '设置标签'"
      :visible.sync="showTagDialog"
      append-to-body
      :close-on-click-modal="false"
      width="1000px"
      @close="onSetTagCancel"
    >
      <TagSelectForm
        :ids="tagIds"
        @cancel="onSetTagCancel"
        @confirm="onSetTagSuccess"
      />
    </el-dialog>
  </section>
</template>

<script>
import {
  GetClientById,
  PutClientStage,
  PutClientOwner,
  GetUpdateOwnerCheck,
  GetUpdateStageCheck,
  GetSignCheck,
} from "./api";
import { GetClientStagesAll } from "@/views/client/clientStages/api";
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { ShowApiError } from "@/request/error";
import BaseDetail from "./components/BaseDetail";
import ClientFollowUpRecord from "./components/ClientFollowUpRecord";
import ClientPayments from "./components/ClientPayments";
import ClientContracts from "./components/ClientContracts";
import ClientInvitations from "./components/ClientInvitations";
import clientControl from "./mixins/clientControl";
import ClientSignForm from "./components/ClientSignForm";
import QuickClientTableList from "./components/QuickClientTableList";
import TagSelectForm from "@/views/client/clientTags/components/TagSelectForm";
import CheckClaim from "./components/CheckClaim";
export default {
  components: {
    CheckClaim,
    BaseDetail,
    ClientFollowUpRecord,
    ClientPayments,
    ClientContracts,
    ClientSignForm,
    ClientInvitations,
    TagSelectForm,
    QuickClientTableList,
  },
  mixins: [clientControl],
  data() {
    return {
      showSingDialog: false,
      showTagDialog: false,
      showQuickList: true,
      submitting: false,
      currentId: null,
      loading: true,
      detail: {},
      ownerOptions: [],
      stageOptions: [],
      tagSize: "medium",
      tabs: [
        { id: 0, name: "详细信息", icon: "" },
        { id: 1, name: "跟进记录", icon: "followRecord" },
        { id: 4, name: "邀约", icon: "invitations" },
        { id: 2, name: "合同", icon: "contracts" },
        { id: 3, name: "收款", icon: "paymentsManager" },
      ],
      tagIds: [],
      tabActive: 0,
    };
  },
  watch: {
    $route: {
      handler() {
        if (
          this.$route.name === "CientsDetail" &&
          this.$route.params.id &&
          this.$route.params.id !== null
        ) {
          this.currentId = Number(this.$route.params.id);
          this.getClienDetail(this.currentId);
          if (this.$route.params.tabId) {
            this.$setStorage(
              `client_tab_id_${this.currentId}`,
              this.$route.params.tabId
            );
          }
          if (this.$getStorage(`client_tab_id_${this.currentId}`)) {
            this.tabActive = this.$getStorage(
              `client_tab_id_${this.currentId}`
            );
          }
        } else {
          this.currentId = null;
          this.tabActive = 0;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getClientStageOptions();
    this.getClientOwnerOptions();
  },
  methods: {
    getClienDetail(id = "") {
      this.loading = true;
      GetClientById(id)
        .then((res) => {
          this.detail = {
            canUpdateChecking: false,
            canUpdateOwnerChecking: false,
            canUpdateStageChecking: false,
            canUpdateSignChecking: false,
            canDisableChecking: false,
            canDisable: false,
            canUpdate: false,
            canStage: false,
            canSign: false,
            canOwner: false,
            ...res.data,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取客户详情数据失败", err);
        });
    },
    getClientOwnerOptions() {
      GetClientOwnersAll()
        .then((res) => {
          this.ownerOptions = res.data;
        })
        .catch((err) => {
          ShowApiError("获取负责人选单失败", err);
        });
    },
    getClientStageOptions() {
      GetClientStagesAll()
        .then((res) => {
          this.stageOptions = res.data;
        })
        .catch((err) => {
          ShowApiError("获取阶段选单失败", err);
        });
    },
    onRefresh() {
      this.getClienDetail(this.currentId);
    },
    onEdit() {
      this.$router.push({
        name: "CientsForm",
        params: {
          id: this.currentId,
        },
      });
    },
    handleEnableClient() {
      this.submitting = true;
      this.ToggleDisableClient(this.detail, false)
        .then(() => {
          this.submitting = false;
          this.onRefresh();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
    handleDisableClient() {
      this.submitting = true;
      this.ToggleDisableClient(this.detail, true)
        .then(() => {
          this.submitting = false;
          this.onRefresh();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
    handleStartClient() {
      this.submitting = true;
      this.ToggleStartClient(this.currentId, true)
        .then(() => {
          this.submitting = false;
          this.onRefresh();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
    handleUnStartClient() {
      this.submitting = true;
      this.ToggleStartClient(this.currentId, false)
        .then(() => {
          this.submitting = false;
          this.onRefresh();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
    onStageChange(val) {
      if (this.detail.canStage) {
        PutClientStage(this.currentId, val)
          .then(() => {
            this.submitting = false;
            this.$message.success("操作成功");
            this.onRefresh();
          })
          .catch((err) => {
            this.submitting = false;
            ShowApiError("变更阶段失败", err);
            this.onRefresh();
          });
        return false;
      }
      this.detail.canUpdateStageChecking = true;
      GetUpdateStageCheck(this.detail.id)
        .then((res) => {
          this.detail.canUpdateStageChecking = false;
          this.detail.canStage = res.data;
          if (res.data) {
            this.submitting = true;
            PutClientStage(this.currentId, val)
              .then(() => {
                this.submitting = false;
                this.$message.success("操作成功");
                this.onRefresh();
              })
              .catch((err) => {
                this.submitting = false;
                ShowApiError("变更阶段失败", err);
                this.onRefresh();
              });
          } else {
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          this.detail.canStage = false;
          this.detail.canUpdateStageChecking = false;
          this.$message.warning("检验异常，请稍后重试!");
        });
    },
    onOwnerChange(val) {
      if (this.detail.canOwner) {
        this.submitting = true;
        PutClientOwner(this.currentId, val)
          .then(() => {
            this.submitting = false;
            this.$message.success("操作成功");
            this.onRefresh();
          })
          .catch((err) => {
            this.submitting = false;
            this.onRefresh();
            ShowApiError("变更负责人失败", err);
          });
        return false;
      }
      this.detail.canUpdateOwnerChecking = true;
      GetUpdateOwnerCheck(this.detail.id)
        .then((res) => {
          this.detail.canUpdateOwnerChecking = false;
          this.detail.canOwner = res.data;
          if (res.data) {
            this.submitting = true;
            PutClientOwner(this.currentId, val)
              .then(() => {
                this.submitting = false;
                this.$message.success("操作成功");
                this.onRefresh();
              })
              .catch((err) => {
                this.submitting = false;
                this.onRefresh();
                ShowApiError("变更负责人失败", err);
              });
          } else {
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          this.detail.canOwner = false;
          this.detail.canUpdateOwnerChecking = false;
          this.$message.warning("检验异常，请稍后重试!");
        });
    },
    onSetSign() {
      if (this.detail.canSign) {
        this.showSingDialog = true;
        return false;
      }
      this.detail.canUpdateSignChecking = true;
      GetSignCheck(this.currentId)
        .then((res) => {
          this.detail.canUpdateSignChecking = false;
          this.detail.canSign = res.data;
          if (res.data) {
            this.showSingDialog = true;
          } else {
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          this.detail.canSign = false;
          this.detail.canUpdateSignChecking = false;
          this.$message.warning("检验异常，请稍后重试!");
        });
    },
    onTabChange(item) {
      this.$setStorage(`client_tab_id_${this.currentId}`, item.id);
      this.tabActive = item.id;
    },
    onFormCancel() {
      this.showSingDialog = false;
    },
    onFormSuccess() {
      this.showSingDialog = false;
      this.onRefresh();
    },
    onShowTagDialog() {
      this.tagIds = this.detail.tags.map((item) => item.id);
      this.showTagDialog = true;
    },
    onSetTagCancel() {
      this.showTagDialog = false;
      this.tagIds = [];
    },
    onSetTagSuccess(ids) {
      this.handleSetClientTag(this.detail.id, ids).then(() => {
        this.$message.success("设置成功");
        this.showTagDialog = false;
        this.tagIds = [];
        this.onRefresh();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$quick-list-width: 400px;
.base-page {
  box-sizing: border-box;
  text-align: left;
  position: relative;
  transition: all 0.3s linear;
  overflow: hidden;
  &.show-quick-list {
    padding-right: $quick-list-width +5;
  }
  .base-page-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  ::v-deep {
    .el-form-item__label-wrap {
      margin-left: 0 !important;
    }
    .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .client-quick-list-wraper {
    box-sizing: border-box;
    width: $quick-list-width;
    background-color: #fff;
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    border-radius: 10px;
    transition: all 0.3s linear;
    border-left: 1px solid #f1f1f1;
    overflow: hidden;
    &.open{
      transform: translateX(0px);
    }
    &.close{
      transform: translateX(400px);
    }
  }
  .base-page-section {
    padding: 15px 30px;
    border-radius: 10px;
    margin: 10px 5px 10px 0;
  }
  .page-header {
    width: auto;
    border-radius: 10px;
    margin: 10px 5px 10px 0;
    padding-bottom: 0;
    position: relative;
    .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-title-name {
        flex: 1;
      }
    }
    .page-control {
      flex: auto;
      text-align: right;
    }

    .client-control-row {
      box-sizing: border-box;
      // padding-top: 20px;
      min-height: 40px;
      .client-tab-wrap {
        box-sizing: border-box;
        min-height: 40px;
        font-size: 0;
        .client-tab-item {
          display: inline-block;
          padding: 10px 15px;
          font-size: 14px;
          color: #333;
          cursor: pointer;
          transition: all 0.3s linear;
          &:hover {
            background-color: #f1f1f1;
            color: #5699f8;
          }
          &.active {
            background-color: #f1f1f1;
            color: #2f82f8;
          }
        }
      }
      .client-sttting-wrap {
        max-height: 100%;
        ::v-deep {
          .el-input__inner {
            border-bottom: 0px solid transparent;
            border-radius: 0;
          }
        }
      }
    }
  }
}
.tags-group {
  box-sizing: border-box;
  padding-top: 20px;
}
.clients-desc {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 5px 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.tag {
  margin-right: 10px;
}
.clients-desc {
  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>