<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="150px"
    class="demo-ruleForm"
    :disabled="submitting"
  >
    <el-form-item label="客户" prop="clientId">
      <BasicSelect
        v-model="form.clientId"
        :disabled="!isEmprty(clientId)"
        :allOptions="_clientOptions"
      />
    </el-form-item>
    <el-form-item label="接待人（可多选）" prop="followUpUserIds">
      <BasicSelect
        :multiple="true"
        v-model="form.followUpUserIds"
        :allOptions="ownerOptions"
      />
    </el-form-item>
    <el-form-item label="客户考察日期" prop="visitDate">
      <el-date-picker
        style="width: 100%"
        v-model="form.visitDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="点击选择"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="考察情况" prop="statusId">
      <BasicSelect v-model="form.statusId" :allOptions="statusesOptions" />
    </el-form-item>
    <el-form-item
      label="改期时间"
      prop="changedVisitDate"
      v-if="form.statusId === 'CHANGE'"
    >
      <el-date-picker
        style="width: 100%"
        v-model="form.changedVisitDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="点击选择"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="客户到访人" prop="visitorName">
      <el-input
        v-model="form.visitorName"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="邀约类型" prop="typeId">
      <BasicSelect v-model="form.typeId" :allOptions="typeOptions" />
    </el-form-item>

    <el-form-item label="提醒日期" prop="remindDate">
      <el-date-picker
        style="width: 100%"
        v-model="form.remindDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="点击选择"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入"
        clearable
        v-model="form.remark"
      >
      </el-input>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel('form')">关 闭</el-button>
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('form')"
        v-if="!isEmprty(id)"
        >{{ submitting ? "提交中..." : "提交修改" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import {
  PostClientInvitations,
  PutClientInvitationsById,
  GetClientInvitationsById,
} from "../api";
import BasicSelect from "@/components/BasicSelect";
import { ShowApiError } from "@/request/error";
import formSelection from "../mixins/formSelection";
import { isEmprty } from "@/utils/validate";
export default {
  components: { BasicSelect },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    clientId: {
      type: [Number],
      default: null,
    },
  },
  mixins: [formSelection],
  data() {
    return {
      submitting: false,
      form: {
        clientId: null,
        followUpUserIds: [],
        remark: "",
        visitDate: "",
        visitorName: "",
        statusId: "",
        typeId: null,
        remindDate: "",
        changedVisitDate: "",
      },
      rules: {
        changedVisitDate: [
          { required: true, message: "请选择改期时间", trigger: "change" },
        ],
        statusId: [
          { required: true, message: "请选择考察情况", trigger: "change" },
        ],
        clientId: [
          { required: true, message: "请选择客户", trigger: "change" },
        ],
        followUpUserIds: [
          { required: true, message: "请选择负责人", trigger: "change" },
        ],
        visitDate: [
          { required: true, message: "请选择考察日期", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.initFormSelection();
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.initForm();
          this.loading = true;
        }
      },
      immediate: true,
    },
    clientId: {
      handler() {
        this.initClientParams();
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    initClientParams() {
      if (!isEmprty(this.clientId)) {
        this.form.clientId = this.clientId;
      } else {
        this.form.clientId = null;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostClientInvitations(this.form)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新建成功!");
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("提交新建错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutClientInvitationsById(this.id, this.form)
            .then(() => {
              this.$message.success("修改成功");
              this.submitting = false;
              this.initForm();
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetClientInvitationsById(id)
        .then((res) => {
          let { followUpUsers } = res.data;
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.form.followUpUserIds = followUpUsers.map((item) => item.id);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        clientId: null,
        followUpUserIds: [],
        remark: "",
        visitDate: "",
        visitorName: "",
        statusId: "",
        typeId:null,
        remindDate: "",
        changedVisitDate: "",
      };
      this.initClientParams();
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>