import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { GetClientInvitationTypeAll } from "@/views/client/clientInvitationType/api";
import { GetUsersAll } from "@/views/users/api";
import { GetClientInvitationsStatuses, GetClientInvitationsVisitStatuses } from "../api";
import { GetDepartmentsAll } from "@/views/departments/api";
export default {
    data() {
        return {
            ownerOptions: [],
            userOptions: [],
            statusesOptions: [],
            departmentOptions: [],
            visitStatusesOptions: [],
            typeOptions: [],
        }
    },
    computed: {
        _clientOptions() {
            return this.$store.state.basicOption.clientOptions
        }
    },
    methods: {
        initFormSelection() {
            GetClientOwnersAll().then(res => {
                this.ownerOptions = res.data;
            })
            GetUsersAll().then(res => {
                this.userOptions = res.data;
            })
            GetClientInvitationsStatuses().then(res => {
                this.statusesOptions = res.data
            })
            GetDepartmentsAll().then(res => {
                this.departmentOptions = res.data
            })
            GetClientInvitationsVisitStatuses().then(res => {
                this.visitStatusesOptions = res.data
            })
            GetClientInvitationTypeAll().then(res => {
                this.typeOptions = res.data
            })
        }
    }
}