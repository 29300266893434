<template>
  <section class="client-payment-wrap">
    <DetailBlock name="邀约信息">
      <template #right
        ><el-button
          class="float-r"
          size="small"
          @click="formDialog = true"
          v-if="
            checkPermission([
              'PAYMENT_ADMIN',
              'PAYMENT_GROUP_ADMIN',
              'PAYMENT_USER',
            ])
          "
          >添加邀约信息</el-button
        ></template
      >
      <NoData v-if="tableData.length <= 0" />
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-if="tableData.length > 0"
      >
        <el-table-column prop="clientName" label="状态" width="100" align="center" fixed="left">
          <template slot-scope="scope">
            <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
            <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="clientName" label="客户名称" min-width="180">
        </el-table-column>
        <el-table-column prop="statusName" label="考察情况" min-width="80">
        </el-table-column>
        
        <el-table-column
          prop="visitDate"
          label="客户考察日期"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column prop="createdAt" label="创建日期" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date_time }}
          </template>
        </el-table-column>
        <el-table-column prop="createdByName" label="创建人" min-width="100"></el-table-column>
        <el-table-column prop="lastModifiedAt" label="最后修改时间" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.lastModifiedAt | date_time }}
          </template>
        </el-table-column>
        <el-table-column
          prop="lastModifiedByName"
          min-width="100"
          label="最后修改人"
        ></el-table-column>
        <el-table-column
          prop="control"
          label="操作"
          width="130px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              详情
            </el-button>
            <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
             v-if="scope.row.disabled"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagenation" v-if="tableData.length > 0">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="page + 1"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </section>
    </DetailBlock>

    <el-dialog
      :title="isEmprty(currentId) ? '添加邀约记录' : '修改邀约记录'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <Form
        :id="currentId"
        :clientId="currentClientId"
        @cancel="onFormCancel"
        @success="onFormSuccess"
      />
    </el-dialog>
     <el-dialog
      title="邀约详情"
      :visible.sync="detaiDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      @close="onDetailClose"
    >
      <Detail :id="currentId" />
    </el-dialog>
  </section>
</template>

<script>
import { GetClientInvitationsList } from "@/views/client/clientInvitations/api";
import Form from "@/views/client/clientInvitations/components/Form";
import NoData from "@/components/NoData";
import { checkPermission } from "@/utils/auth";
import { isEmprty } from "@/utils/validate";
import invitationControl from "@/views/client/clientInvitations/mixins/comonControl.js";
import DetailBlock from "@/components/DetailBlock";
import Detail from "@/views/client/clientInvitations/components/Detail";
export default {
  components: { NoData, Form, DetailBlock, Detail },
  mixins: [invitationControl],
  props: {
    clientId: {
      type: [Number],
      default: null,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentId: null,
      currentClientId: null,
      formDialog: false,
      detaiDialog: false,
      loading: false,
      page: 0,
      size: 20,
      total: 0,
      tableData: [],
    };
  },
  watch: {
    clientId: {
      handler() {
        if (!isEmprty(this.clientId)) {
          this.currentClientId = this.clientId
          this.getTableData();
        } else {
          this.currentClientId = null
          this.page = 0;
          this.tableData = [];
          this.loading = false;
        }
      },
      immediate: true,
    },
    refresh: {
      handler() {
        if (this.refresh) {
          this.getTableData();
        }
      },
    },
  },
  methods: {
    isEmprty,
    checkPermission,
    getTableData() {
      this.loading = true;
      GetClientInvitationsList({
        page: this.page,
        size: this.size,
        clientId: this.currentClientId,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              canDisableChecking: false,
              canUpdateChecking: false,
              canUpdate: false,
              canDisable: false,
              isEdit: false,
            };
          });
          this.total = totalElements;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showRow(row) {
      this.currentId = row.id;
      this.detaiDialog = true;
    },
    onDetailClose(){
      this.currentId = "";
      this.detaiDialog = false;
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    onFormCancel() {
      this.currentId = "";
      this.formDialog = false;
    },
    onFormSuccess() {
      this.currentId = "";
      this.formDialog = false;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 15px;
  text-align: right;
  min-height: 40px;
}
.pagenation {
  margin-top: 25px;
  text-align: right;
}
</style>