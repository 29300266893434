var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"follow-ups-record-wrap"},[_c('DetailBlock',{attrs:{"name":"跟进记录信息"},scopedSlots:_vm._u([{key:"right",fn:function(){return [(
          _vm.checkPermission([
            'CLIENT_ADMIN',
            'CLIENT_GROUP_ADMIN',
            'CLIENT_USER',
          ])
        )?_c('el-button',{staticClass:"float-r",attrs:{"size":"small"},on:{"click":function($event){_vm.formDialog = true}}},[_vm._v("添加跟进记录")]):_vm._e()]},proxy:true}])},[(_vm.recordLists.length <= 0)?_c('NoData'):_vm._e(),(_vm.recordLists.length > 0)?_c('el-timeline',_vm._l((_vm.recordLists),function(item,index){return _c('el-timeline-item',{key:item.id,attrs:{"color":item.color,"timestamp":item.date,"placement":"top"}},[_c('el-card',{attrs:{"shadow":"hover"}},[_c('p',{staticClass:"record-text"},[_c('span',{staticClass:"start",staticStyle:{"margin-right":"15px"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.star),expression:"item.star"}],staticClass:"el-icon-star-on text-start",staticStyle:{"cursor":"pointer","font-size":"20px"}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!item.star),expression:"!item.star"}],staticClass:"el-icon-star-off",staticStyle:{"cursor":"pointer","font-size":"17px"}})]),_c('el-tag',{staticStyle:{"margin-right":"15px"}},[_vm._v(_vm._s(item.followUpStatusName))])],1),_c('div',{staticClass:"record-content"},[_c('span',{staticClass:"lable"},[_vm._v("跟进内容：")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(item.content)+" ")])]),(item.files.length > 0)?_c('div',{staticClass:"record-content"},[_c('span',{staticClass:"lable"},[_vm._v("附件：")]),_c('div',{staticClass:"content"},[_vm._l((item.files),function(item){return [_c('FilesItem',{key:item.id,staticClass:"mt-r",attrs:{"item":item,"showFileName":false,"showFileDesc":false,"showCheckBox":false}})]})],2)]):_vm._e(),_c('div',{staticClass:"record-update-info clearfix"},[(
                _vm.checkPermission([
                  'CLIENT_ADMIN',
                  'CLIENT_GROUP_ADMIN',
                  'CLIENT_USER',
                  'CLIENT_OBSERVER',
                ])
              )?_c('span',{staticClass:"float-l",staticStyle:{"cursor":"pointer"},attrs:{"type":"text"},on:{"click":function($event){return _vm.handleStart(item, index)}}},[_vm._v(_vm._s(item.star ? "取消星标" : "设为星标"))]):_vm._e(),_vm._v(" "+_vm._s(item.lastModifiedByName)+" 更新于 "+_vm._s(_vm._f("date_time")(item.lastModifiedAt))+" ")])])],1)}),1):_vm._e(),(_vm.recordLists.length > 0)?_c('section',{staticClass:"pagenation"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","current-page":_vm.page + 1,"page-size":_vm.size,"total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1),_c('el-dialog',{attrs:{"title":"添加跟进记录","visible":_vm.formDialog,"append-to-body":"","close-on-click-modal":false,"width":"600px"},on:{"update:visible":function($event){_vm.formDialog=$event}}},[(_vm.formDialog)?_c('Form',{attrs:{"clientId":_vm.currentClientId},on:{"cancel":_vm.onFormCancel,"success":_vm.onFormSuccess}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }