<template>
  <div class="quick-list">
    <div class="quick-list-container">
      <div class="filter-wraper">
        <el-form ref="form" :model="tableFilters" label-width="auto">
          <el-row :gutter="20">
            <el-col :sm="24" :md="12">
              <el-form-item label="客户名称">
                <el-input
                  v-model="tableFilters.name"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="onSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12">
              <el-form-item label="联系人">
                <el-input
                  v-model="tableFilters.mainContactName"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="onSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <template v-if="showMoreFilter">
              <el-col :sm="24" :md="12">
                <el-form-item label="联系人电话">
                  <el-input
                    v-model="tableFilters.mainContactPhone"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="onSearch"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="12">
                <el-form-item label="客户阶段(多选)">
                  <BasicSelect
                    multiple
                    v-model="tableFilters.stageId"
                    :allOptions="stageOptions"
                  />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="12">
                <el-form-item label="负责人(多选)">
                  <BasicSelect
                    multiple
                    v-model="tableFilters.ownerId"
                    :allOptions="ownerOptions"
                  />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="12">
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="tableFilters.createdAt"
                    type="daterange"
                    style="width: 100%"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </template>

            <el-col :sm="24">
              <el-form-item style="text-align: right;margin-top:10px">
                <el-button type="primary" @click="onSearch" size="small">查询</el-button>
                <el-button @click="onResetFilter" size="small">重置</el-button>
                <el-button
                  type="text"
                  @click="showMoreFilter = !showMoreFilter"
                >
                  更多
                  <i
                    :class="
                      showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></i>
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="table-wraper">
        <div
          class="table-item base-shadow"
          v-for="item in tableData"
          :key="item.id"
        >
          <div
            class="name text-nav"
            title="点击查看详情"
            @click="onShowDetail(item)"
          >
            【{{ item.stageName }}】- {{ item.name }}
          </div>
          <div class="desc">
            <span>联系人：{{
              item.mainContact !== null ? item.mainContact.name : ""
            }}
            - {{ item.mainContact !== null ? item.mainContact.phoneNo : "" }}</span>
            <span style="padding-left:10px">创建日期：{{item.createdAt | date}}</span>
          </div>
          <div class="desc">
            <span>负责人： {{item.ownerName}}</span>
            <span style="padding-left:10px">跟进记录：<ClientFollowUpRecordListItem :id="item.id" /></span>
          </div>
        </div>
      </div>
    </div>

    <div class="table-pagenation">
      <el-pagination
        :disabled="tableLoading"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-size="size"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GetClientsList } from "../api";
import { onClearFilter } from "@/utils/common";
import { ShowApiError } from "@/request/error";
import BasicSelect from "@/components/BasicSelect";
import ClientFollowUpRecordListItem from "./ClientFollowUpRecordListItem";
import clientForm from "@/views/client/mixins/clientForm";
export default {
  name: "QuickClientTableList",
  mixins: [clientForm],
  components: { BasicSelect, ClientFollowUpRecordListItem },
  data() {
    return {
      tableLoading: false,
      showMoreFilter: false,
      tableFilters: {
        createdAt: [],
        name: "",
        stageId: null,
        ownerId: null,
        mainContactName: "",
        mainContactPhone: "",
      },
      page: 0,
      size: 30,
      total: 0,
      totalPages: 0,
      tableData: [],
    };
  },
  created() {
    this.getStageOptions();
    this.getOwnerOptions();
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val - 1;
      this.onRefresh();
    },
    getTableData() {
      this.tableLoading = true;
      GetClientsList({
        page: this.page,
        size: this.size,
        name: this.tableFilters.name,
        stageId: this.tableFilters.stageId,
        "contacts.name": this.tableFilters.mainContactName,
        "contacts.phoneNo": this.tableFilters.mainContactPhone,
        createdAt: this.tableFilters.createdAt,
        ownerId: this.tableFilters.ownerId,
      })
        .then((res) => {
          let { content, totalElements, totalPages } = res.data;
          this.tableData = content;
          this.total = totalElements;
          this.totalPages = totalPages;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("客户快速选择拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onSearch() {
      this.page = 0;
      this.onRefresh();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onShowDetail(row) {
      this.$router.replace({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
        query: {
          name: row.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$pagenataion-height: 50px;
.quick-list {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-bottom: $pagenataion-height;
  position: relative;
  .quick-list-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .filter-wraper {
    box-sizing: border-box;
    padding: 10px;
    ::v-deep {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .table-wraper {
    box-sizing: border-box;
    width: 100%;
    padding-left: 10px;
    padding-right: 5px;
    .table-item {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #f1f1f1;
      border-radius: 4px;
      &:hover {
        background-color: #f1f1f1;
      }
      .name {
        font-size: 16px;
        padding-bottom: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .desc {
        font-size: 14px;
        color: #989898;
        padding: 2px;
      }
    }
  }
  .table-pagenation {
    width: 100%;
    height: $pagenataion-height;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #f1f1f1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>